<template>
    <h4>INTERESTINTERESTINTEREST</h4>
</template>

<script>
export default ({
    // add props for dynamic routing
    props: ['stockDetail']
})
</script>
